import React from "react";
import { Row, Col } from "react-bootstrap";
import Card from "../Card/Card";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CURRENCY } from "../../Network/ApiPaths";
let storeFresh;
// let clientHost;
//let storeUserId;
// let storeIdValue;
let storeDet;
let storeAddress;
let storeName;
class ExecutiveReportsComponent extends React.Component {
  constructor(props) {
    super();
    const userCheck = window.localStorage.getItem("access");
    storeDet = JSON.parse(userCheck);
    if (storeDet !== null) {
      storeAddress = storeDet.address;
      storeName = storeDet.name;
    } else {
      storeAddress = "";
      storeName = "";
    }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      storeName = JSON.parse(window.localStorage.getItem("clientAccess"))
        .storeName;
      // clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
  }
  pdfCheck = () => {
    // alert("hgfdfghj");
    if (this.props.executiveReport) {
      const doc = new jsPDF();
      doc.autoTable({
        columnStyles: { europe: { halign: "center" } },
        theme: "plain",
        headStyles: { fillColor: [255, 0, 0] },
        html: "#executiveTable",
      });
      doc.save(
        `${storeName}_executiveReport_${
          this.props.reportsDate === ""
            ? this.props.date
            : this.props.reportsDate
        }to ${
          this.props.reportsDate2 === ""
            ? this.props.date
            : this.props.reportsDate2
        }.pdf`
      );
    } else {
      alert("No data");
    }
  };
  render() {
    return (
      <section className="client no-padding-top">
        <div className="container-fluid">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-close">
                <div className="dropdown">
                  <div
                    id="closeCard4"
                    aria-haspopup="true"
                    className="dropdown-toggle"
                  >
                    {/* <i className="fa fa-ellipsis-v"></i> */}
                    <Col md={3}>
                      <Card
                        ctAllIcons
                        content={
                          <Row style={{ position: "absolute", right: "0px" }}>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="provide Start Date"
                              defaultValue={this.props.date}
                              name="getReportsByDate"
                              max={this.props.date}
                              onChange={this.props.getExecutiveReportsByDate}
                              style={{
                                position: "relative",
                                right: "105%",
                                bottom: "5px",
                              }}
                            />
                            <input
                              type="date"
                              className="form-control"
                              placeholder="provide Start Date"
                              defaultValue={this.props.date}
                              name="getReportsByDate2"
                              max={this.props.date}
                              style={{ position: "relative", bottom: "42px" }}
                              onChange={this.props.getExecutiveReportsByDate2}
                            />
                          </Row>
                        }
                      />
                    </Col>
                  </div>
                </div>
              </div>
              <div className="card-header d-flex align-items-center">
                <h3 className="h4">Executive Sales Reports</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {/* <div style={{ display: "none" }}> */}
                  <div style={{ height: "60%", overflow: "auto" }}>
                    <table className="table" id="executiveTable">
                      <thead>
                        {/* <tr>
                          <th>Range</th> 
                        </tr> */}
                      </thead>
                      {this.props.executiveReport ? (
                        <>
                          {this.props.executiveReport.map((p, index) => (
                            <tbody>
                              {index === 0 ? (
                                <>
                                  <tr
                                    style={{
                                      background: "#c4c4c4",
                                      color: "black",
                                    }}
                                  >
                                    {/*<td>Range</td> */}
                                    <th></th>
                                    <th key="europe">{p.range}</th>

                                    <th></th>
                                    <th></th>
                                    {/* <th></th> */}
                                  </tr>
                                  <tr>
                                    <th>Order Count</th>
                                    <td>{p.report.OrderCount}&nbsp;</td>
                                    <th></th>
                                    <th></th>
                                    {/* <th></th> */}
                                  </tr>
                                  <tr>
                                    <th>Delivery Charge</th>
                                    <td>
                                      {p.report.DeliveryCharge}&nbsp;
                                    </td>{" "}
                                    <th></th>
                                    <th></th>
                                  </tr>
                                  <tr>
                                    <th>CGST</th>
                                    <td>
                                      {" "}
                                      <i
                                        className={CURRENCY}
                                        aria-hidden="true"
                                      ></i>
                                      {Number(p.report.CGST).toFixed(2)}
                                    </td>
                                    <th></th>
                                    <th></th>
                                    {/* <th></th> */}
                                  </tr>
                                  <tr>
                                    <th>SGST</th>
                                    <td>
                                      <i
                                        className={CURRENCY}
                                        aria-hidden="true"
                                      ></i>
                                      {Number(p.report.SGST).toFixed(2)}
                                    </td>
                                    <th></th>
                                    <th></th>
                                    {/* <th></th> */}
                                  </tr>

                                  <tr>
                                    <th>Net Sale</th>
                                    <td>
                                      {" "}
                                      <i
                                        className={CURRENCY}
                                        aria-hidden="true"
                                      ></i>
                                      {p.report.NetSale}
                                    </td>
                                    <th></th>
                                    <th></th>
                                    {/* <th></th> */}
                                  </tr>

                                  <tr>
                                    <th> Total</th>
                                    <td>
                                      {" "}
                                      <i
                                        className={CURRENCY}
                                        aria-hidden="true"
                                      ></i>
                                      {Number(p.report.GrandTotal).toFixed(2)}
                                    </td>
                                    <th></th>
                                    <th></th>
                                    {/* <th></th> */}
                                  </tr>
                                  <tr>
                                    <th>Roundoff </th>
                                    <td>
                                      {" "}
                                      <i
                                        className={CURRENCY}
                                        aria-hidden="true"
                                      ></i>
                                      {Number(p.report.roundoff).toFixed(2)}
                                    </td>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                  <tr>
                                    <th>Waived Off </th>
                                    <td>
                                      {" "}
                                      <i
                                        className={CURRENCY}
                                        aria-hidden="true"
                                      ></i>
                                      {Number(p.report.waivedoff).toFixed(2)}
                                    </td>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                </>
                              ) : null}
                            </tbody>
                          ))}
                          {this.props.executiveReport.map((p, index) => (
                            <tbody>
                              {index === 1 ? (
                                <>
                                  <tr
                                    style={{
                                      background: "#c4c4c4",
                                      color: "black",
                                    }}
                                  >
                                    {/*<td>Range</td> */}
                                    <th></th>
                                    <th>{p.range}</th>
                                    <th></th>
                                    <th></th>
                                    {/* <th></th> */}
                                  </tr>
                                  {/* <tr>
                                    <th></th>
                                    <th>Delivery Charge</th>
                                    <td>{p.report.DeliveryCharge}&nbsp;</td>
                                  </tr> */}
                                  <tr>
                                    <th>Order Count</th>
                                    <td>{p.report.OrderCount}&nbsp;</td>
                                    <th></th>
                                    <th></th>
                                    {/* <th></th> */}
                                  </tr>
                                  <tr>
                                    <th>CGST</th>
                                    <td>
                                      {" "}
                                      <i
                                        className={CURRENCY}
                                        aria-hidden="true"
                                      ></i>
                                      {Number(p.report.CGST).toFixed(2)}
                                    </td>
                                    <th></th>
                                    <th></th>
                                    {/* <th></th> */}
                                  </tr>
                                  <tr>
                                    <th>SGST</th>
                                    <td>
                                      {" "}
                                      <i
                                        className={CURRENCY}
                                        aria-hidden="true"
                                      ></i>
                                      {Number(p.report.SGST).toFixed(2)}
                                    </td>
                                    <th></th>
                                    <th></th>
                                    {/* <th></th> */}
                                  </tr>
                                  <tr>
                                    <th> Total</th>
                                    <td>
                                      <i
                                        className={CURRENCY}
                                        aria-hidden="true"
                                      ></i>
                                      {Number(p.report.GrandTotal).toFixed(2)}
                                    </td>
                                    <th></th>
                                    <th></th>
                                    {/* <th></th> */}
                                  </tr>
                                  <tr>
                                    <th>Roundoff </th>
                                    <td>
                                      {" "}
                                      <i
                                        className={CURRENCY}
                                        aria-hidden="true"
                                      ></i>
                                      {Number(p.report.roundoff).toFixed(2)}
                                    </td>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                  <tr>
                                    <th>Waived Off </th>
                                    <td>
                                      {" "}
                                      <i
                                        className={CURRENCY}
                                        aria-hidden="true"
                                      ></i>
                                      {Number(p.report.waivedoff).toFixed(2)}
                                    </td>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                </>
                              ) : null}
                            </tbody>
                          ))}
                          {this.props.executiveReport
                            ? this.props.executiveReport.map((p, index) => (
                                <tbody>
                                  {index === 2 ? (
                                    <>
                                      <tr
                                        style={{
                                          background: "#c4c4c4",
                                          color: "black",
                                        }}
                                      >
                                        {/*<td>Range</td> */}
                                        <th></th>
                                        <th>{p.range}</th>

                                        <th></th>
                                        <th></th>
                                        {/* <th></th> */}
                                      </tr>
                                      <tr>
                                        <th>Order Count</th>
                                        <td>
                                          {
                                            p.report
                                              ? // p.report.OrderCount!== null?
                                                p.report.OrderCount
                                              : 0
                                            // : null
                                          }
                                          &nbsp;
                                        </td>
                                        <th></th>
                                        <th></th>
                                        {/* <th>/th> */}
                                      </tr>
                                      <tr>
                                        <th>Platform</th>
                                        <td>
                                          {p.report ? p.report.platform : "-"}
                                        </td>
                                        <th></th>
                                        <th></th>
                                        {/* <th></th> */}
                                      </tr>
                                      <tr>
                                        <th>Total</th>
                                        <td>
                                          <i
                                            className={CURRENCY}
                                            aria-hidden="true"
                                          ></i>
                                          {p.report
                                            ? Number(p.report.Total).toFixed(2)
                                            : 0}
                                        </td>
                                        <th></th>
                                        <th></th>
                                        {/* <th></th> */}
                                      </tr>
                                    </>
                                  ) : null}
                                </tbody>
                              ))
                            : null}
                          {/* {this.props.executiveReport.map((p, index) => (
                            <tbody>
                              {index === 3 ? (
                                <>
                                  <tr>
                                     <th>{p.range}</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                  <tr>
                                    <th></th>
                                    <th>Order Count</th>
                                    <td>{p.report.OrderCount}&nbsp;</td>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                  <tr>
                                    <th></th>
                                    <th>Payment Mode</th>
                                    <td>{p.report.payment_mode}</td>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                  <tr>
                                    <th></th>
                                    <th> Total</th>
                                    <td>{Number(p.report.Total).toFixed(2)}</td>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                </>
                              ) : null}
                            </tbody>
                          ))} */}
                          {this.props.paymentReport ? (
                            this.props.paymentReport.map((p, index) => (
                              <tbody>
                                <tr
                                  style={{
                                    background: "#c4c4c4",
                                    color: "black",
                                  }}
                                >
                                  <th></th>
                                  <th>Pay Mode&nbsp;-&nbsp;{p.payment_mode}</th>

                                  <th></th>
                                  <th></th>
                                </tr>
                                <tr>
                                  <th>Order Count</th>
                                  <td>{p.OrderCount}&nbsp;</td>
                                  <th></th>
                                  <th></th>
                                </tr>
                                <tr>
                                  <th>Total</th>
                                  <td>
                                    <i
                                      className={CURRENCY}
                                      aria-hidden="true"
                                    ></i>
                                    {Number(p.Total).toFixed(2)}
                                  </td>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </tbody>
                            ))
                          ) : (
                            <div>
                              <div
                                style={{
                                  textAlign: "center",
                                  padding: "50px",
                                  position: "relative",
                                  // right: "-50%",
                                  // paddingTop: "30%",
                                }}
                              >
                                <h1 style={{ fontSize: "35px" }}> No Data </h1>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div>
                          <div
                            style={{
                              textAlign: "center",
                              padding: "50px",
                              position: "relative",
                              right: "-50%",
                              paddingTop: "30%",
                            }}
                          >
                            <h1 style={{ fontSize: "35px" }}> No Data </h1>
                          </div>
                        </div>
                      )}
                    </table>
                  </div>
                </div>
              </div>
              {/* <div>
                {clientHost ? (
                  <a
                    style={{ position: "absolute", right: "55%",top:"3%"  }}
                    href={
                      clientHost.split("/backend/api")[0] +
                      // `/reports/Report/dailySale`
                      `/reports/Report/getBillReport?store_id=${
                        storeFresh.storeId
                      }&startDate=${
                        this.props.reportsDate
                          ? this.props.reportsDate
                          : this.props.date
                      }&endDate=${
                        this.props.reportsDate2
                          ? this.props.reportsDate2
                          : this.props.date
                      }`
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                     Download 
                  </a>
                ) : null}
              </div> */}
              {/* <div ref={(el) => (this.componentRef = el)}>*/}
              <div style={{ display: "none" }}>
                <div ref={(el) => (this.componentRef2 = el)}>
                  <table
                  // style={{ position: "relative", right: "-5%" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ position: "relative", left: "35%" }}>
                          {storeName}
                        </th>
                      </tr>
                    </thead>
                    {storeAddress ? (
                      <tbody>
                        <tr>
                          <th style={{ textAlign: "center" }}>
                            {storeAddress.split(",")[0]},{" "}
                            {storeAddress.split(",")[1]},
                          </th>
                        </tr>
                        <tr>
                          <th style={{ textAlign: "center" }}>
                            {storeAddress.split(",")[2]},{" "}
                            {storeAddress.split(",")[3]},
                          </th>
                        </tr>
                        <tr>
                          <th style={{ textAlign: "center" }}>
                            {storeAddress.split(",")[4]},{" "}
                            {storeAddress.split(",")[5]}
                          </th>
                        </tr>
                      </tbody>
                    ) : null}
                  </table>
                  {/* <br/>  */}
                  <table>
                    <tbody style={{ float: "left" }}>
                      <tr>
                        <td>
                          <strong>
                            {this.props.reportsDate === ""
                              ? this.props.date
                              : this.props.reportsDate}
                          </strong>
                          {" to "}
                          <strong>
                            {this.props.reportsDate2 === ""
                              ? this.props.date
                              : this.props.reportsDate2}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  {/* <tr>
                                <td>--------------------</td>
                              </tr> */}
                  <table>
                    {this.props.executiveReport ? (
                      <>
                        {this.props.executiveReport.map((p, index) => (
                          <tbody>
                            {index === 0 ? (
                              <>
                                <tr>
                                  {/*<td>Range</td> */}
                                  <th>{p.range}</th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                                <tr>
                                  {/* <th></th> */}
                                  <td>Delivery Charge</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{p.report.DeliveryCharge}&nbsp;</td>
                                </tr>
                                <tr>
                                  {/* <th></th> */}
                                  <td>Order Count</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{p.report.OrderCount}&nbsp;</td>
                                </tr>
                                <tr>
                                  {/* <th></th> */}
                                  <td>CGST</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{Number(p.report.CGST).toFixed(2)}</td>
                                </tr>
                                <tr>
                                  {/* <th></th> */}
                                  <td>SGST</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{Number(p.report.SGST).toFixed(2)}</td>
                                </tr>
                                <tr>
                                  <td>Net Total</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{p.report.NetSale}</td>
                                </tr>
                                <tr>
                                  {/* <th></th> */}
                                  <td>Grand Total</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {Number(p.report.GrandTotal).toFixed(2)}
                                  </td>
                                </tr>
                                {/* <tr>
                                  <td>Sub Total</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{p.report.SubTotal}</td>
                                </tr> */}
                                <tr>
                                  <td>Roundoff</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {/* <i className={CURRENCY}></i> */}
                                    {Number(p.report.roundoff).toFixed(2)}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Waived Off</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {/* <i className={CURRENCY}></i> */}
                                    {Number(p.report.waivedoff).toFixed(2)}
                                  </td>
                                </tr>
                              </>
                            ) : null}
                          </tbody>
                        ))}
                        {this.props.executiveReport.map((p, index) => (
                          <tbody>
                            {index === 1 ? (
                              <>
                                <tr>
                                  {/*<td>Range</td> */}
                                  <th>{p.range}</th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                                {/* <tr>
                                   
                                    <td>Delivery Charge</td>
                                    <th></th>
                                    <th></th>
                                    <td>{p.report.DeliveryCharge}&nbsp;</td>
                                  </tr> */}
                                <tr>
                                  {/* <th></th> */}
                                  <td>Order Count</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{p.report.OrderCount}&nbsp;</td>
                                </tr>
                                <tr>
                                  {/* <th></th> */}
                                  <td>CGST</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{Number(p.report.CGST).toFixed(2)}</td>
                                </tr>
                                <tr>
                                  {/* <th></th> */}
                                  <td>SGST</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{Number(p.report.SGST).toFixed(2)}</td>
                                </tr>
                                <tr>
                                  {/* <th></th> */}
                                  <td> Total</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {Number(p.report.GrandTotal).toFixed(2)}
                                  </td>
                                </tr>
                                {/* <tr>
                                    
                                    <td>Sub Total</td>
                                    <th></th>
                                    <th></th>
                                    <td>{p.report.SubTotal}</td>
                                    
                                  </tr> */}
                              </>
                            ) : null}
                          </tbody>
                        ))}
                        {this.props.executiveReport.map((p, index) => (
                          <tbody>
                            {index === 2 ? (
                              <>
                                <tr>
                                  {/*<td>Range</td> */}
                                  <th>{p.range}</th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                                <tr>
                                  {/* <th></th> */}
                                  <td>Order Count</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {p.report ? p.report.OrderCount : 0}&nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  {/* <th></th> */}
                                  <td>Platform</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{p.report ? p.report.platform : "-"}</td>
                                </tr>
                                <tr>
                                  {/* <th></th> */}
                                  <td> Total</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {p.report
                                      ? Number(p.report.Total).toFixed(2)
                                      : 0}
                                  </td>
                                </tr>
                              </>
                            ) : null}
                          </tbody>
                        ))}
                        <tr>
                          <th>&nbsp;</th>
                        </tr>
                        <tbody>
                          <tr>
                            <th>Pay Mode</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                          <tr>
                            <td>Payment Type</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          {this.props.paymentReport
                            ? this.props.paymentReport.map((p, index) => (
                                <tr>
                                  <td>{p.payment_mode}</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td style={{ textAlign: "center" }}>
                                    {Number(p.Total).toFixed(2)}
                                  </td>
                                </tr>
                              ))
                            : null}
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>Printed on {new Date().toLocaleString()}</td>
                          </tr>
                        </tbody>
                      </>
                    ) : null}
                  </table>
                </div>
              </div>
              <a
                href="#/"
                style={{
                  position: "absolute",
                  right: "50%",
                  padding: "15px",
                  color: "red",
                }}
                onClick={this.pdfCheck}
              >
                <i className="fa fa-file-pdf-o"></i>
              </a>
              {/* </div>  */}
              <ReactToPrint
                // onAfterPrint={this.refreshPage}
                trigger={() => (
                  <a
                    style={{
                      position: "absolute",
                      right: "45%",
                      padding: "15px",
                    }}
                    //   type="button"
                    //   className="btn btn-primary"
                    href="#/"
                  >
                    Print
                  </a>
                )}
                content={() => this.componentRef2}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card">
              {/* <div className="card-close">
                <div className="dropdown">
                  <div
                    id="closeCard4"
                    aria-haspopup="true"
                    className="dropdown-toggle"
                  >
                     <Col md={3}>
                      <Card
                        ctAllIcons
                        content={
                          <Row style={{ position: "absolute", right: "0px" }}>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="provide Start Date"
                              defaultValue={this.props.date}
                              name="getReportsByDate"
                              max={this.props.date}
                              onChange={this.props.getExecutiveReportsByDate}
                              style={{
                                position: "relative",
                                right: "105%",
                                bottom: "5px",
                              }}
                            />
                            <input
                              type="date"
                              className="form-control"
                              placeholder="provide Start Date"
                              defaultValue={this.props.date}
                              name="getReportsByDate2"
                              max={this.props.date}
                              style={{ position: "relative", bottom: "42px" }}
                              onChange={this.props.getExecutiveReportsByDate2}
                            />
                          </Row>
                        }
                      />
                    </Col>
                  </div>
                </div>
              </div> */}
              {/* <div className="card-header d-flex align-items-center">
                <h3 className="h4">Executive Sales Reports For Payments</h3>
              </div> */}
              {/* <div className="card-body">
                <div className="table-responsive">
                  <div style={{ height: "300px", overflow: "auto" }}>
                    <table className="table table-striped table-sm">
                      {this.props.paymentReport ? (
                        this.props.paymentReport.map((p, index) => (
                          <tbody>
                            <tr>
                              <th>{p.payment_mode}</th>
                              <th></th>
                              <th></th>
                              <th></th>
                            </tr>
                            <tr>
                              <th></th>
                              <th>Order Count</th>
                              <td>{p.OrderCount}&nbsp;</td>
                              <th></th>
                            </tr>
                            <tr>
                              <th></th>
                              <th>Total</th>
                              <td>{Number(p.Total).toFixed(2)}</td>
                              <th></th>
                            </tr>
                          </tbody>
                        ))
                      ) : (
                        <div>
                          <div
                            style={{
                              textAlign: "center",
                              padding: "50px",
                              position: "relative",
                              // right: "-50%",
                              // paddingTop: "30%",
                            }}
                          >
                            <h1 style={{ fontSize: "35px" }}> No Data </h1>
                          </div>
                        </div>
                      )}
                    </table>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ExecutiveReportsComponent;
