import React from "react";
import { HOST_PREFIX } from "../../Network/ApiPaths";
const SubCategoriesComponent = (props) => (
  <section className="tables">
    <div className="container-fluid">
      <div className="row">
        <div className="card-body text-center">
          <div
            id="myModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            className="modal fade text-left"
          >
            <div role="document" className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 id="exampleModalLabel" className="modal-title">
                    ADD Sub Category
                    {/* &nbsp;
                    {props.data
                      ? props.data.map((p) => p.info.category_id)[0]
                      : null} */}
                  </h4>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    className="close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        name="newName"
                        onChange={props.fieldChange}
                        // type="email"
                        placeholder="name"
                        className="form-control"
                      />
                    </div>
                    {/* <div className="form-group row">
                      <div className="col-sm-9">
                        <div>
                          <input
                            id="option"
                            type="checkbox"
                            value=""
                            defaultChecked
                          />
                          <label htmlFor="option"> Active</label>
                        </div>
                      </div>
                    </div> */}
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={props.addNewSubCategory}
                        data-dismiss="modal"
                      >
                        Add SubCategory
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body text-center">
          <div
            id="myModal2"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            className="modal fade text-left"
          >
            <div role="document" className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 id="exampleModalLabel" className="modal-title">
                    Edit Details
                  </h4>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    className="close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>

                <div className="modal-body">
                  {props.itemDefaultDetails !== "" ? (
                    <form className="form-horizontal">
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            id="inputHorizontalSuccess"
                            className="form-control form-control-success"
                            name="updatedName"
                            onChange={props.fieldChange}
                          />
                          <small className="form-text">
                            {props.subDefaultDetails.name}
                          </small>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-9 offset-sm-3">
                          <input
                            type="submit"
                            value="Signin"
                            className="btn btn-primary"
                            data-dismiss="modal"
                          />
                        </div>
                      </div>
                    </form>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="form-group row">
                <label className="col-sm-8 form-control-label">
                  <br />
                  <h3 className="h4">Select Category</h3>
                </label>
                <div className="page">
                  <div className="page-content d-flex align-items-stretch">
                    <nav className="side-navbar">
                      <ul className="list-unstyled">
                        <li className="active">
                          <a
                            href="#exampledropdownDropdownA"
                            aria-expanded="false"
                            data-toggle="collapse"
                          >
                            <i className="icon-interface-windows"></i>
                            {props.cname}
                          </a>
                          <ul
                            id="exampledropdownDropdownA"
                            className={
                              props.collapse ? "" : "collapse list-unstyled "
                            }
                          >
                            {props.catData.map((p, index) => (
                              <li
                                key={index}
                                onClick={() => props.selectedCat(p.id, p.name)}
                                className={props.catid === p.id ? "active" : ""}
                              >
                                <a href="#profile">
                                  <i className=""></i>
                                  <b>{p.name}</b>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-close">
              <div className="dropdown">
                {
                  // props.data ? (
                  // props.data.length === 0 &&
                  props.new === false ? null : (
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#myModal"
                      className="btn btn-primary"
                    >
                      Add SubCategory
                    </button>
                  )
                  // ) : null
                }
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                <button
                  type="button"
                  id="closeCard4"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  aria-labelledby="closeCard4"
                  className="dropdown-menu dropdown-menu-right has-shadow"
                >
                  <a href="#profile" className="dropdown-item remove">
                    <i className="fa fa-times"></i>Close
                  </a>
                  <a href="#profile" className="dropdown-item edit">
                    <i className="fa fa-gear"></i>Edit
                  </a>
                </div>
              </div>
            </div>
            <div className="card-header d-flex align-items-center">
              <h3 className="h4">SubCategories</h3>
            </div>

            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Image</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.data ? (
                      props.data.map((p) => (
                        <tr>
                          <td>{p.name}</td>
                          <td>{p.status}</td>
                          <td>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={
                                HOST_PREFIX +
                                "images/menu/" +
                                p.id +
                                "/" +
                                p.image_path
                              }
                            >
                              {p.image_path}
                            </a>
                          </td>
                          <td
                            data-target="#myModal2"
                            onClick={() => props.onSubCategoryTriggerEdit(p)}
                          >
                            edit
                          </td>
                          <td
                            onClick={() => props.onSubCategoryTriggerDelete(p)}
                          >
                            delete
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="input-group-prepend">
                  Page:<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {/* {props.data.map((p, index) => (
                    <span
                      className="input-group-text"
                      onClick={() => props.next(index)}
                    >
                      {index}
                    </span>
                  ))} */}
                  <span
                    className="input-group-text"
                    onClick={() => props.next(0)}
                  >
                    1
                  </span>
                  <span
                    className="input-group-text"
                    onClick={() => props.next(10)}
                  >
                    2
                  </span>
                  <span
                    className="input-group-text"
                    onClick={() => props.next(20)}
                  >
                    3
                  </span>
                  <span
                    className="input-group-text"
                    onClick={() => props.next(30)}
                  >
                    4
                  </span>
                  <span
                    className="input-group-text"
                    onClick={() => props.next(40)}
                  >
                    5
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default SubCategoriesComponent;
