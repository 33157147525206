import React from "react";
import DunzoStatementsComponent from "./DunzoStatementsComponent";
import Uri from "../../Network/Uri";
import getResponseClient from "../../Network/GetClientDataResponse";
// import getResponseClient from "../../Network/GetClientDataResponse";
// import Uri from "../../Network/Uri";
// let storeIdValue;
// let storeDet;
let clientHost;
let storeFresh;

class DunzoStatementsContainer extends React.Component {
  constructor(props) {
    super();
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    // if (storeFresh !== null) {
    //   storeIdValue = storeFresh.storeId;
    // } else {
    //   storeIdValue = "";
    // }
    this.state = {
      dunzoData: [],

      date: "",
      salesDate: "",

      salesDate2: "",

      // OrderDate: dateFormat("paddingShortDate")
    };
  }
  getDunzoStatement = () => {
    let todaysDunzoStatements = new Date().toISOString().slice(0, 10);
    this.setState({ date: todaysDunzoStatements });
    let salesDate = this.state.salesDate;
    if (salesDate !== "") {
      todaysDunzoStatements = salesDate;
    }
    var apiPath = new Uri(
      clientHost +
        `Reports/getMonthlyDunzoCharge?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", todaysDunzoStatements)
      .addQueryParam("endDate", todaysDunzoStatements);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        console.log(onSuccessResponse.response);
        this.setState({
          dunzoData: onSuccessResponse.response,
        });
      },
      (onError) => {
        // alert(onError.message);
      }
    );
  };

  getDunzoStatementByDate = (e) => {
    // let date = new Date(e.target.value).toISOString().slice(0, 10);
    // console.log("0",e.target.value,"1",date,"2",new Date(e.target.value))
    let salesDate = new Date(e.target.value).toISOString().slice(0, 10);
    this.setState({ salesDate });

    var apiPath = new Uri(
      clientHost +
        `Reports/getMonthlyDunzoCharge?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", salesDate)
      .addQueryParam(
        "endDate",
        this.state.salesDate2 === ""
          ? new Date().toISOString().slice(0, 10)
          : this.state.salesDate2
      );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState(
          {
            dunzoData: onSuccessResponse.response,

            // type: onSuccessResponse.result[0].order_status,
          }
          // this.getItemwiseList()
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  getDunzoStatementByDate2 = (e) => {
    let salesDate2 = new Date(e.target.value).toISOString().slice(0, 10);
    this.setState({ salesDate2 });

    var apiPath = new Uri(
      clientHost +
        `Reports/getMonthlyDunzoCharge?store_id=${storeFresh.storeId}`
    )
      .addQueryParam(
        "startDate",
        this.state.salesDate === ""
          ? new Date().toISOString().slice(0, 10)
          : this.state.salesDate
      )
      .addQueryParam(
        "endDate",

        salesDate2
      );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState(
          {
            dunzoData: onSuccessResponse.response,

            // type: onSuccessResponse.result[0].order_status,
          }
          // this.getItemwiseList()
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };

  render() {
    return (
      <div>
        <DunzoStatementsComponent
          getDunzoStatementByDate={this.getDunzoStatementByDate}
          dunzoData={this.state.dunzoData}
          salesDate={this.state.salesDate}
          salesDate2={this.state.salesDate2}
          date={this.state.date}
          //salesReport={this.state.salesReport}
          getDunzoStatementByDate2={this.getDunzoStatementByDate2}
        />
      </div>
    );
  }
  async componentDidMount() {
    this.getDunzoStatement();
  }
}
export default DunzoStatementsContainer;
