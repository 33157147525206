import React from "react";
import DunzoReportsComponent from "./DunzoReportsComponent";
// import getResponse from "../../Network/GetDataResponse";
// import postDataResponse from "../../Network/PostDataResponse";
import Uri from "../../Network/Uri";
import getResponseClient from "../../Network/GetClientDataResponse";
// import getResponseClient from "../../Network/GetClientDataResponse";
// import Uri from "../../Network/Uri";
// let storeIdValue;
// let storeDet;
let clientHost;
let storeFresh;
class DunzoReportsContainer extends React.Component {
  constructor(props) {
    super();
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    // if (storeFresh !== null) {
    //   storeIdValue = storeFresh.storeId;
    // } else {
    //   storeIdValue = "";
    // }
    this.state = {
      dunzoReports: [],

      date: "",
      salesDate: "",

      salesDate2: "",

      // OrderDate: dateFormat("paddingShortDate")
    };
  }
  getDunzoReports = () => {
    let todaysDunzoReports = new Date().toISOString().slice(0, 10);
    this.setState({ date: todaysDunzoReports });
    let salesDate = this.state.salesDate;
    if (salesDate !== "") {
      todaysDunzoReports = salesDate;
    }
    var apiPath = new Uri(
      clientHost + `Reports/getDunzoOrderData?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", todaysDunzoReports)
      .addQueryParam("endDate", todaysDunzoReports);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        console.log(onSuccessResponse.response);
        this.setState({
          dunzoReports: onSuccessResponse.response,
        });
      },
      (onError) => {
        // alert(onError.message);
      }
    );
  };

  getDunzoReportsByDate = (e) => {
    // let date = new Date(e.target.value).toISOString().slice(0, 10);
    // console.log("0",e.target.value,"1",date,"2",new Date(e.target.value))
    let salesDate = new Date(e.target.value).toISOString().slice(0, 10);
    this.setState({ salesDate });

    var apiPath = new Uri(
      clientHost + `Reports/getDunzoOrderData?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", salesDate)
      .addQueryParam(
        "endDate",
        this.state.salesDate2 === ""
          ? new Date().toISOString().slice(0, 10)
          : this.state.salesDate2
      );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState(
          {
            dunzoReports: onSuccessResponse.response,

            // type: onSuccessResponse.result[0].order_status,
          }
          // this.getItemwiseList()
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  getDunzoReportsByDate2 = (e) => {
    let salesDate2 = new Date(e.target.value).toISOString().slice(0, 10);
    this.setState({ salesDate2 });

    var apiPath = new Uri(
      clientHost + `Reports/getDunzoOrderData?store_id=${storeFresh.storeId}`
    )
      .addQueryParam(
        "startDate",
        this.state.salesDate === ""
          ? new Date().toISOString().slice(0, 10)
          : this.state.salesDate
      )
      .addQueryParam(
        "endDate",

        salesDate2
      );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState(
          {
            dunzoReports: onSuccessResponse.response,

            // type: onSuccessResponse.result[0].order_status,
          }
          // this.getItemwiseList()
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };

  render() {
    return (
      <div>
        <DunzoReportsComponent
          getDunzoReportsByDate={this.getDunzoReportsByDate}
          dunzoReports={this.state.dunzoReports}
          salesDate={this.state.salesDate}
          salesDate2={this.state.salesDate2}
          date={this.state.date}
          //salesReport={this.state.salesReport}
          getDunzoReportsByDate2={this.getDunzoReportsByDate2}
        />
      </div>
    );
  }
  async componentDidMount() {
    this.getDunzoReports();
  }
}
export default DunzoReportsContainer;
