import React from "react";
import { Row, Col } from "react-bootstrap";
import Card from "../Card/Card";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CURRENCY } from "../../Network/ApiPaths";
let storeFresh;
//let storeUserId;
// let storeIdValue;
let clientHost;
let storeDet;
let storeAddress;
let storeName;
// function seperateOrdersByDate(orders) {
//   const datesArray = orders.map((order) =>
//     order.OrderDate ? order.OrderDate.split(" ")[0] : null
//   );
//   const dateSet = new Set(datesArray);
//   const uniqueDates = Array.from(dateSet);
//   const seperatedOrder = [];
//   uniqueDates.forEach((date) => {
//     const item = {
//       date,
//       orders: orders.filter((order) => {
//         return order.OrderDate ? order.OrderDate.split(" ")[0] === date : null;
//       }),
//     };
//     seperatedOrder.push(item);
//   });
//   return seperatedOrder;
// }
// function seperateItemsByCategory(categories) {
//   const categoryArray = categories.map((category) => category.CategoryName);
//   const categorySet = new Set(categoryArray);
//   const uniqueCategories = Array.from(categorySet);
//   const seperatedCategory = [];
//   uniqueCategories.forEach((CategoryName) => {
//     const items = {
//       CategoryName,
//       categories: categories.filter((category) => {
//         return category.CategoryName === CategoryName;
//       }),
//     };
//     seperatedCategory.push(items);
//   });
//   return seperatedCategory;
// }
class ItemReportsComponent extends React.Component {
  constructor(props) {
    super();
    const userCheck = window.localStorage.getItem("access");
    storeDet = JSON.parse(userCheck);
    if (storeDet !== null) {
      storeAddress = storeDet.address;
    } else {
      storeAddress = "";
    }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      // storeName = storeFresh.storeName;
      storeName = JSON.parse(window.localStorage.getItem("clientAccess"))
        .storeName;
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
  }
  pdfCheck = () => {
    // alert("hgfdfghj");
    if (this.props.catReport) {
      const doc = new jsPDF();
      doc.autoTable({ html: "#my-table1" });
      doc.save(
        `${storeName}_categorywiseReports_${
          this.props.reportsCatDate === ""
            ? this.props.date
            : this.props.reportsCatDate
        }to${
          this.props.reportsCatDate2 === ""
            ? this.props.date
            : this.props.reportsCatDate2
        }.pdf`
      );
    } else {
      alert("No data");
    }
  };
  render() {
    return (
      <section className="client no-padding-top">
        <div className="container-fluid">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-close">
                <div className="dropdown">
                  <div
                    id="closeCard4"
                    aria-haspopup="true"
                    className="dropdown-toggle"
                  >
                    {/* <i className="fa fa-ellipsis-v"></i> */}
                    <Col md={3}>
                      <Card
                        ctAllIcons
                        content={
                          <Row style={{ position: "absolute", right: "0px" }}>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="provide Start Date"
                              defaultValue={this.props.date}
                              name="getCatReportsByDate"
                              max={this.props.date}
                              onChange={this.props.getCatReportsByDate}
                              style={{
                                position: "relative",
                                right: "105%",
                                bottom: "5px",
                              }}
                            />
                            <input
                              type="date"
                              className="form-control"
                              placeholder="provide Start Date"
                              defaultValue={this.props.date}
                              name="getCatReportsByDate2"
                              max={this.props.date}
                              style={{ position: "relative", bottom: "42px" }}
                              onChange={this.props.getCatReportsByDate2}
                            />
                          </Row>
                        }
                      />
                    </Col>
                  </div>
                </div>
              </div>
              <div className="card-header d-flex align-items-center">
                <h3 className="h4">Category Wise Reports</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {/* <div style={{ display: "none" }}> */}
                  <div style={{ height: "400px", overflow: "auto" }}>
                    <table
                      id="my-table1"
                      className="table table-striped table-sm"
                    >
                      <thead>
                        <tr>
                          {/* <th>Date</th> */}
                          <th>Name</th>
                          <th style={{ textAlign: "center" }}>Quantity</th>
                          <th style={{ textAlign: "center" }}>Total Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.catReport ? (
                          this.props.catReport.map((p, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  {/* <td>{p.Date.split(" ")[0]}</td> */}
                                  <td>{p.name}&nbsp;</td>
                                  <td style={{ textAlign: "center" }}>
                                    {p.quantity}&nbsp;
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <i
                                      className={CURRENCY}
                                      aria-hidden="true"
                                    ></i>
                                    {p.totalamount}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <div
                            style={{
                              textAlign: "center",
                              padding: "50px",
                              position: "relative",
                              right: "-25%",
                              paddingTop: "25%",
                            }}
                          >
                            <h1 style={{ fontSize: "35px" }}> No Data </h1>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                {this.props.catReport ? (
                  <>
                    {clientHost ? (
                      <a
                        style={{
                          position: "absolute",
                          right: "55%",
                          top: "3%",
                          color: "black",
                        }}
                        href={
                          clientHost.split("/backend/api")[0] +
                          // `/reports/Report/dailySale`
                          `/reports/Report/getCategoryReport?store_id=${
                            storeFresh.storeId
                          }&startDate=${
                            this.props.reportsCatDate
                              ? this.props.reportsCatDate
                              : this.props.date
                          }&endDate=${
                            this.props.reportsCatDate2
                              ? this.props.reportsCatDate2
                              : this.props.date
                          }`
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </a>
                    ) : null}
                  </>
                ) : null}
              </div>
              {/* <div ref={(el) => (this.componentRef = el)}>*/}
              <div style={{ display: "none" }}>
                <div ref={(el) => (this.componentRef2 = el)}>
                  <table
                  // style={{ position: "relative", right: "-5%" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ position: "relative", left: "35%" }}>
                          {storeName}
                        </th>
                      </tr>
                    </thead>
                    {storeAddress ? (
                      <tbody>
                        <tr>
                          <th style={{ textAlign: "center" }}>
                            {storeAddress.split(",")[0]},{" "}
                            {storeAddress.split(",")[1]},
                          </th>
                        </tr>
                        <tr>
                          <th style={{ textAlign: "center" }}>
                            {storeAddress.split(",")[2]},{" "}
                            {storeAddress.split(",")[3]},
                          </th>
                        </tr>
                        <tr>
                          <th style={{ textAlign: "center" }}>
                            {storeAddress.split(",")[4]},{" "}
                            {storeAddress.split(",")[5]}
                          </th>
                        </tr>
                      </tbody>
                    ) : null}
                  </table>
                  {/* <br/>  */}
                  <table>
                    <tbody style={{ float: "left" }}>
                      <tr>
                        <td>
                          {/* Date: */}
                          <strong>
                            {this.props.reportsCatDate === ""
                              ? this.props.date
                              : this.props.reportsCatDate}
                          </strong>
                          {" to "}
                          <strong>
                            {this.props.reportsCatDate2 === ""
                              ? this.props.date
                              : this.props.reportsCatDate2}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  {/* <tr>
                                <td>--------------------</td>
                              </tr> */}
                  <table>
                    <tbody>
                      {/* <tr>
                                <td>--------------------</td>
                              </tr> */}
                      <tr>
                        {/* <th>
                                  Items&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Size&nbsp;Amt
                                </th> */}
                        {/* <th style={{columnSpan:"2"}}>Date</th> */}
                        <th>Name</th>
                        <th style={{ textAlign: "center" }}>Quantity</th>
                        <th style={{ textAlign: "center" }}>Total Amount</th>
                      </tr>

                      <br />
                    </tbody>

                    {/* <tr>
                                <td>--------------------</td>
                              </tr> */}
                    <tbody>
                      {this.props.catReport
                        ? this.props.catReport.map((p, index) => {
                            return (
                              <>
                                {/* <tr><th>{p.Date.split(" ")[0]}</th></tr> */}
                                <tr key={index}>
                                  {/* <td>
                                              {p.name.length > 11 ? (
                                                <>{p.name.substring(0, 11)}...</>
                                              ) : null}{" "}
                                              {p.name.length === 11 ? (
                                                <>{p.name}&nbsp;&nbsp;&nbsp;</>
                                              ) : null}{" "}
                                              {p.name.length === 10 ? (
                                                <>{p.name}&nbsp;&nbsp;&nbsp;&nbsp;</>
                                              ) : null}{" "}
                                              {p.name.length === 9 ? (
                                                <>
                                                  {p.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </>
                                              ) : null}{" "}
                                              {p.name.length === 8 ? (
                                                <>
                                                  {p.name}
                                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </>
                                              ) : null}{" "}
                                              {p.name.length === 7 ? (
                                                <>
                                                  {p.name}
                                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </>
                                              ) : null}{" "}
                                              {p.name.length === 6 ? (
                                                <>
                                                  {p.name}
                                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </>
                                              ) : null}{" "}
                                              {p.name.length < 5 ? (
                                                <>
                                                  {p.name}
                                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </>
                                              ) : null}
                                              </td> */}

                                  <td>{p.name}&nbsp;</td>
                                  <td style={{ textAlign: "center" }}>
                                    {p.quantity}&nbsp;
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {p.totalamount}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : null}
                      <tr>
                        <td>Printed on {new Date().toLocaleString()}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <a
                href="#/"
                style={{
                  position: "absolute",
                  right: "60%",
                  padding: "15px",
                  color: "red",
                }}
                onClick={this.pdfCheck}
              >
                <i className="fa fa-file-pdf-o"></i>
              </a>
              {/* </div>  */}
              <ReactToPrint
                // onAfterPrint={this.refreshPage}
                trigger={() => (
                  <a
                    style={{
                      position: "absolute",
                      right: "45%",
                      padding: "15px",
                    }}
                    //   type="button"
                    //   className="btn btn-primary"
                    href="#/"
                  >
                    Print
                  </a>
                )}
                content={() => this.componentRef2}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ItemReportsComponent;

