import React, { Component } from "react";
import { Line, Bar } from "react-chartjs-2";
// import Uri from "../../Network/Uri";
// import getResponseClient from "../../Network/GetClientDataResponse";

// let clientHost;
// let storeFresh;
class Chart extends Component {
  constructor(props) {
    super(props);
    // const client = window.localStorage.getItem("clientAccess");
    // storeFresh = JSON.parse(client);
    // if (storeFresh !== null) {
    //   clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    // }
    this.state = { chartData: [] };
  }
  static defaultProps = {
    displayTitle: true,
    displayLegend: true,
    legendPosition: "right",
    location: "City",
  };
  render() {
    const { analyticsData } = this.props;
    let data;
    if (analyticsData) {
      let mbaData = analyticsData.items_list;
      if (mbaData !== []) {
        // console.log(mbaData);
        //       confidence: 0.4463157894736842
        // item1: "Chicken Delight"
        // item2: "Pepsi 1"
        // lift: 1.2034315072584982
        data = (canvas) => {
          const ctx = canvas.getContext("2d");
          const gradient1 = ctx.createLinearGradient(150, 0, 150, 300);
          gradient1.addColorStop(0, "rgba(133, 180, 242, 0.91)");
          gradient1.addColorStop(1, "rgba(255, 119, 119, 0.94)");
          return {
            labels: mbaData?.map((d) => d.lift),
            datasets: [
              {
                label: "Data Set One",
                fill: true,
                lineTension: 0.3,
                backgroundColor: gradient1,
                borderColor: gradient1,
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                borderWidth: 1,
                pointBorderColor: gradient1,
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: gradient1,
                pointHoverBorderColor: "rgba(220,220,220,1)",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: mbaData?.map((d) => d.confidence),
                spanGaps: false,
              },
            ],
          };
        };
      }
    } else {
      data = (canvas) => {
        const ctx = canvas.getContext("2d");
        const gradient1 = ctx.createLinearGradient(150, 0, 150, 300);
        gradient1.addColorStop(0, "rgba(133, 180, 242, 0.91)");
        gradient1.addColorStop(1, "rgba(255, 119, 119, 0.94)");
        return {
          labels: [],
          datasets: [
            {
              label: "Data Set One",
              fill: true,
              lineTension: 0.3,
              backgroundColor: gradient1,
              borderColor: gradient1,
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              borderWidth: 1,
              pointBorderColor: gradient1,
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: gradient1,
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: [],
              spanGaps: false,
            },
          ],
        };
      };
    }
    return (
      <>
        {analyticsData ? (
          <>
            <Bar
              data={data}
              options={{
                // title: {
                //   display: this.props.displayTitle,
                //   text: "Largest Cities In " + this.props.location,
                //   fontSize: 25,
                // },
                // legend: {
                //   display: this.props.displayLegend,
                //   position: this.props.legendPosition,
                // },
                scales: {
                  xAxes: [
                    {
                      display: true,
                      gridLines: {
                        color: "#eee",
                      },
                    },
                  ],
                  yAxes: [
                    {
                      display: true,
                      gridLines: {
                        color: "#eee",
                      },
                    },
                  ],
                },
              }}
            />
            <Line
              data={data}
              options={{
                legend: { labels: { fontColor: "#777", fontSize: 12 } },
                scales: {
                  xAxes: [
                    {
                      display: true,
                      gridLines: {
                        color: "#eee",
                      },
                    },
                  ],
                  yAxes: [
                    {
                      display: true,
                      gridLines: {
                        color: "#eee",
                      },
                    },
                  ],
                },
              }}
            />
            {/* <Pie
          data={this.state.chartData}
          options={{
            title: {
              display: this.props.displayTitle,
              text: "Largest Cities In " + this.props.location,
              fontSize: 25,
            },
            legend: {
              display: this.props.displayLegend,
              position: this.props.legendPosition,
            },
          }}
        /> */}
          </>
        ) : (
          <div>No Data</div>
        )}
      </>
    );
  }

  async componentDidMount() {
    // var apiPath = new Uri(clientHost + `Reports/getMonthlyReport`)
    //   .addQueryParam("startDate", "2020-01-01")
    //   .addQueryParam("endDate", "2021-12-12")
    //   .addQueryParam("store_id", storeFresh.storeId);
    // getResponseClient(
    //   apiPath,
    //   storeFresh.key,
    //   (onSuccessResponse) => {
    //     this.setState({
    //       monthlyData: onSuccessResponse.response,
    //     });
    //   },
    //   (onError) => {
    //     // alert(onError.message);
    //   }
    // );
  }
}

export default Chart;
