import React from "react";
import { Row, Col } from "react-bootstrap";
import Card from "../Card/Card";
// const Dunzodata = [
//     {
//         "TotalOrders": "9",
//         "dunzocharge": "0",
//         "storename": "Cream And Fudge Horamavu",
//         "TOTAL": "2510",
//         "FINAL": "37.65000000000009"
//     }

// ]
class DunzoStatementsComponent extends React.Component {
  constructor(props) {
    super();
  }
 
  render() {
    return  (
        <section className="client no-padding-top">
        <div className="container-fluid">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-close">
                <div className="dropdown">
                  <div
                    id="closeCard4"
                    aria-haspopup="true"
                    className="dropdown-toggle"
                  >
                    {/* <i className="fa fa-ellipsis-v"></i> */}
                    <Col md={3}>
                      <Card
                        ctAllIcons
                        content={
                          <Row style={{ position: "absolute", right: "0px" }}>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="provide Start Date"
                              defaultValue={this.props.date}
                              name="getDunzoStatementByDate"
                              max={this.props.date}
                              onChange={this.props.getDunzoStatementByDate}
                              style={{
                                position: "relative",
                                right: "105%",
                                bottom: "5px",
                              }}
                            />
                            <input
                              type="date"
                              className="form-control"
                              placeholder="provide Start Date"
                              defaultValue={this.props.date}
                              name="getDunzoStatementByDate2"
                              max={this.props.date}
                              style={{ position: "relative", bottom: "42px" }}
                              onChange={this.props.getDunzoStatementByDate2}
                            />
                          </Row>
                        }
                      />
                    </Col>
                  </div>
                </div>
              </div>
              <div className="card-header d-flex align-items-center">
                <h3 className="h4">Dunzo Delivery Data</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {/* <div style={{ display: "none" }}> */}
                  <div
                //    style={{ height: "400px", overflow: "auto" }}
                   >
                    <table
                      id="my-table1"
                      className="table table-striped table-sm"
                    >
                      <thead>
                        <tr>
                         <th style={{textAlign:"center"}}>Name</th>
                         <th style={{textAlign:"center"}}>Total Orders</th>
                         <th style={{textAlign:"center"}}>Dunzo Charge</th>
                         <th style={{textAlign:"center"}}>Total</th>
                         <th style={{textAlign:"center"}}>Final</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.dunzoData? (
                          this.props.dunzoData.map((p, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  {/* <td>{p.Date.split(" ")[0]}</td> */}
                                  <td style={{textAlign:"center"}}>{p.storename}&nbsp;</td>
                                  <td style={{textAlign:"center"}} >
                                    {p.TotalOrders}&nbsp;
                                  </td>
                                  <td style={{textAlign:"center"}}>
                                    {p.dunzocharge}
                                  </td>
                                  <td style={{textAlign:"center"}}>
                                      {p.TOTAL}
                                  </td>
                                  <td style={{textAlign:"center"}}>{p.FINAL}</td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <div
                            style={{
                              textAlign: "center",
                              padding: "50px",
                              position: "relative",
                              right: "-25%",
                              paddingTop: "25%",
                            }}
                          >
                            <h1 style={{ fontSize: "35px" }}> No Data </h1>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
             
            
              
            </div>
          </div>
        </div>
      </section>

);
                            }
                        }
export default DunzoStatementsComponent ;