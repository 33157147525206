import React from "react";
import StoresComponent from "./StoresComponent";
import { STORE_LIST } from "../../Network/ApiPaths";
import getResponse from "../../Network/GetDataResponse";
import Uri from "../../Network/Uri";
import StoreDetails from "../StoreDetails";
class StoresContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      stores: [],
      sid: "",
      storeDetails: false,
    };
  }
  setSID = (sId) => {
    this.setState({ sid: sId, storeDetails: true });
  };
  goToStores = () => {
    this.setState({ storeDetails: false });
  };
  render() {
    return (
      <div>
        {this.state.storeDetails ? (
          <div>
            <StoreDetails goToStores={this.goToStores} sID={this.state.sid} />
          </div>
        ) : (
          <StoresComponent data={this.state.stores} sid={this.setSID} />
        )}
      </div>
    );
  }
  async componentDidMount() {
    var apiPath = new Uri(STORE_LIST);
    getResponse(
      apiPath,
      (onSuccessResponse) => {
        this.setState({
          stores: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }
}
export default StoresContainer;
