import React from "react";
import { Row, Col } from "react-bootstrap";
import Card from "../Card/Card";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import ReactExport from "react-data-export";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
// import { CURRENCY } from "../../Network/ApiPaths";
// import NumberFormat from "react-number-format";

let storeFresh;
//let storeUserId;
// let storeIdValue;
let clientHost;
let storeDet;
let storeAddress;
let storeName;
// function seperateOrdersByDate(orders) {
//   const datesArray = orders.map((order) => order.OrderDate.split(" ")[0]);
//   const dateSet = new Set(datesArray);
//   const uniqueDates = Array.from(dateSet);
//   const seperatedOrder = [];
//   uniqueDates.forEach((date) => {
//     const item = {
//       date,
//       orders: orders.filter((order) => {
//         return order.OrderDate.split(" ")[0] === date;
//       }),
//     };
//     seperatedOrder.push(item);
//   });
//   return seperatedOrder;
// }
// function seperateItemsByCategory(categories) {
//   const categoryArray = categories.map((category) => category.CategoryName);
//   const categorySet = new Set(categoryArray);
//   const uniqueCategories = Array.from(categorySet);
//   const seperatedCategory = [];
//   uniqueCategories.forEach((CategoryName) => {
//     const items = {
//       CategoryName,
//       categories: categories.filter((category) => {
//         return category.CategoryName === CategoryName;
//       }),
//     };
//     seperatedCategory.push(items);
//   });
//   return seperatedCategory;
// }
class ItemReportsComponent extends React.Component {
  constructor(props) {
    super();
    const userCheck = window.localStorage.getItem("access");
    storeDet = JSON.parse(userCheck);
    if (storeDet !== null) {
      storeAddress = storeDet.address;
    } else {
      storeAddress = "";
    }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      //   storeName = storeFresh.storeName;
      storeName = JSON.parse(window.localStorage.getItem("clientAccess"))
        .storeName;
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
    // const NumberFormat = require('react-number-format');
  }
  pdfCheck = () => {
    if (this.props.ordersList) {
      // alert("hgfdfghj");
      const doc = new jsPDF();
      doc.autoTable({ html: "#my-table1" });
      doc.save(`${storeName}_itemwiseReport_${
        this.props.salesDate === "" ? this.props.date : this.props.salesDate
      }to${
        this.props.salesDate2 === "" ? this.props.date : this.props.salesDate2
      }
.pdf`);
    } else {
      alert("No Data");
    }
  };
  render() {
    return (
      <section className="client no-padding-top">
        <div className="container-fluid">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-close">
                <div className="dropdown">
                  <div
                    id="closeCard4"
                    aria-haspopup="true"
                    className="dropdown-toggle"
                  >
                    {/* <i className="fa fa-ellipsis-v"></i> */}
                    <Col md={3}>
                      <Card
                        ctAllIcons
                        content={
                          <Row style={{ position: "absolute", right: "0px" }}>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="provide Start Date"
                              defaultValue={this.props.date}
                              name="getSalesByDate"
                              max={this.props.date}
                              onChange={this.props.getSalesByDate}
                              style={{
                                position: "relative",
                                right: "105%",
                                bottom: "5px",
                              }}
                            />
                            <input
                              type="date"
                              className="form-control"
                              placeholder="provide Start Date"
                              defaultValue={this.props.date}
                              name="getSalesByDate2"
                              max={this.props.date}
                              style={{ position: "relative", bottom: "42px" }}
                              onChange={this.props.getSalesByDate2}
                            />
                          </Row>
                        }
                      />
                    </Col>
                  </div>
                </div>
              </div>
              <div className="card-header d-flex align-items-center">
                <h3 className="h4">Item Wise Reports</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {/* <div style={{ display: "none" }}> */}
                  <div style={{ height: "400px", overflow: "auto" }}>
                    <table
                      className="table table-striped table-sm"
                      id="my-table1"
                    >
                      <thead>
                        <tr>
                          {/* <th>Items</th> */}
                          <th>Name</th>
                          <th>Quantity</th>
                          <th>size</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.ordersList ? (
                          this.props.ordersList.map((p, index) => {
                            return (
                              <>
                                <tr>
                                  <th>{p.category.name}</th>
                                  <th>{p.category.quantity}</th>
                                  <th></th>
                                  <th>{p.category.totalamount}</th>
                                </tr>
                                {p.items.map((category, index) => (
                                  <tr key={index}>
                                    <td>{category.productname}</td>
                                    <td>{category.quantity}</td>
                                    <td>{category.size}</td>
                                    <td>{category.totalamount}</td>
                                  </tr>
                                ))}
                              </>
                            );
                          })
                        ) : (
                          <tr style={{ height: "353px" }}>
                            <td></td>
                            <td></td>
                            <td style={{ paddingTop: "150px" }}>
                              <h1 style={{ fontSize: "35px" }}> No Data </h1>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                {this.props.ordersList ? (
                  <>
                    {clientHost ? (
                      <a
                        style={{
                          position: "absolute",
                          right: "55%",
                          top: "3%",
                          color: "black",
                        }}
                        href={
                          clientHost.split("/backend/api")[0] +
                          `/reports/Report/getCategoryProductwiseSale?store_id=${
                            storeFresh.storeId
                          }&startDate=${
                            this.props.salesDate
                              ? this.props.salesDate
                              : this.props.date
                          }&endDate=${
                            this.props.salesDate2
                              ? this.props.salesDate2
                              : this.props.date
                          }`
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </a>
                    ) : null}
                  </>
                ) : null}
              </div>
              {/* <table id="my-table">srhgdf</table> */}
              <div style={{ display: "none" }}>
                <div ref={(el) => (this.componentRef = el)}>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ position: "absolute", right: "35%" }}>
                          {storeName}
                        </th>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                    </thead>
                    {storeAddress ? (
                      <tbody style={{ textAlign: "center" }}>
                        <tr style={{ textAlign: "center" }}>
                          <th style={{ textAlign: "center" }}>
                            {storeAddress.split(",")[0]},{" "}
                            {storeAddress.split(",")[1]},
                          </th>
                        </tr>
                        <tr>
                          <th style={{ textAlign: "center" }}>
                            {storeAddress.split(",")[2]},{" "}
                            {storeAddress.split(",")[3]},
                          </th>
                        </tr>
                        <tr>
                          <th style={{ textAlign: "center" }}>
                            {storeAddress.split(",")[4]},{" "}
                            {storeAddress.split(",")[5]},
                          </th>
                        </tr>
                      </tbody>
                    ) : null}
                  </table>
                  <table>
                    <tbody>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            {this.props.salesDate === ""
                              ? this.props.date
                              : this.props.salesDate}
                          </strong>
                          {" to "}
                          <strong>
                            {this.props.salesDate2 === ""
                              ? this.props.date
                              : this.props.salesDate2}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <th>Items</th>
                        <th>Qty</th>
                        <th>Size</th>
                        <th>Amt</th>
                      </tr>
                    </thead>
                    {this.props.ordersList
                      ? this.props.ordersList.map(
                          (p, index) => (
                            // ({ CategoryName, categories }, index) => {
                            // return (
                            <tbody key={index}>
                              <tr>
                                <th>{p.category.name}</th>
                                <th>{p.category.quantity}</th>
                                <th></th>
                                <th>{p.category.totalamount}</th>
                              </tr>
                              {p.items.map((category, index) => (
                                <tr key={index}>
                                  <td>{category.productname}</td>
                                  <td>{category.quantity}</td>
                                  <td>{category.size}</td>
                                  <td>{category.totalamount}</td>
                                </tr>
                              ))}
                              {/* <tr>
                                <th>{p.category.name}</th>
                                <th>{p.category.quantity}</th>
                                <th></th>
                                <th>{p.category.totalamount}</th>
                              </tr> */}
                            </tbody>
                          )
                          //   );
                          // }
                        )
                      : //
                        null}
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    {this.props.ordersData
                      ? this.props.ordersData.map((q, index) => (
                          <tbody>
                            <tr>
                              <th>OrdersCount</th>
                              <th style={{ position: "absolute", left: "50%" }}>
                                {q.OrdersCount}
                              </th>
                            </tr>
                            <tr>
                              <th>Average</th>
                              <th style={{ position: "absolute", left: "50%" }}>
                                {q.Average}
                              </th>
                            </tr>

                            <tr>
                              <th>Net</th>
                              <th style={{ position: "absolute", left: "50%" }}>
                                {q.NetSale}
                              </th>
                            </tr>
                            <tr>
                              <th>CGST</th>
                              <th style={{ position: "absolute", left: "50%" }}>
                                {Number(q.CGST).toFixed(2)}
                              </th>
                            </tr>
                            <tr>
                              <th>SGST</th>
                              <th style={{ position: "absolute", left: "50%" }}>
                                {Number(q.SGST).toFixed(2)}
                              </th>
                            </tr>
                            <tr>
                              <th>Gross</th>
                              <th style={{ position: "absolute", left: "50%" }}>
                                {Number(q.GrossAmount).toFixed(2)}
                              </th>
                            </tr>
                          </tbody>
                        ))
                      : null}
                    <tr>
                      <td>Printed on {new Date().toLocaleString()}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <a
                href="#/"
                style={{
                  position: "absolute",
                  right: "60%",
                  padding: "15px",
                  color: "red",
                }}
                onClick={this.pdfCheck}
              >
                <i className="fa fa-file-pdf-o"></i>
              </a>
              {/* <ExcelFile
                          filename="ordersList"
                          element={<a href="#/">Excel Download</a>}
                        > */}
              {/* <ExcelSheet data={this.props.ordersList} name="Top 10">
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Quantity" value="count" />
                            <ExcelColumn label="Amount" value="amount" /> */}
              {/* <ExcelColumn
                              label="Marital Status"
                              value={(col) =>
                                col.is_married ? "Married" : "Single"
                              }
                            /> */}
              {/* </ExcelSheet>
                        </ExcelFile> */}

              <ReactToPrint
                trigger={() => (
                  <a
                    style={{
                      position: "absolute",
                      right: "45%",
                      padding: "15px",
                    }}
                    href="#/"
                  >
                    Print
                  </a>
                )}
                content={() => this.componentRef}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ItemReportsComponent;
