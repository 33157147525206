import React from "react";
import Footer from "./Footer";
// import Home from "../Components/Home";
import Reports from "../Components/Reports/Reports";
import TakeOrder from "../Components/TakeOrder/TakeOrder";
import Orders from "../Components/Orders/Orders";
class ShopDash extends React.Component {
  constructor(props) {
    super();
    this.state = {
      home: true,
      takeOrder: false,
      orders: false,
    };
  }
  showHome = (e) => {
    e.preventDefault();
    this.setState({
      home: true,
      takeOrder: false,
      orders: false,
    });
  };
  showTakeOrder = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      takeOrder: true,
      orders: false,
    });
  };
  showOrders = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      takeOrder: false,
      orders: true,
    });
  };
  render() {
    const userCheck = window.localStorage.getItem("access");
    return (
      <div className="page-content d-flex align-items-stretch">
        <nav className="side-navbar">
          <div className="sidebar-header d-flex align-items-center">
            <div className="avatar">
              <img
                src="img/brand_logo.jpg"
                alt="..."
                className="img-fluid rounded-circle"
              />
            </div>
            <div className="title">
              <h1 className="h4">{JSON.parse(userCheck).name}</h1>
              <p>{JSON.parse(userCheck).role}</p>
            </div>
          </div>
          <ul className="list-unstyled">
            <li
              onClick={this.showHome}
              className={this.state.home ? "active" : null}
            >
              <a href="#/">
                <i className="icon-home"></i>Home
              </a>
            </li>
            <li
              onClick={this.showTakeOrder}
              className={this.state.takeOrder ? "active" : null}
            >
              <a href="#/">
                <i className="icon-grid"></i>Take Order
              </a>
            </li>
            <li
              onClick={this.showOrders}
              className={this.state.orders ? "active" : null}
            >
              <a href="#/">
                <i className="icon-grid"></i>Orders
              </a>
            </li>
          </ul>
          <span className="heading"></span>
        </nav>
        <div className="content-inner">
          {this.state.home ? <Reports /> : null}
          {this.state.takeOrder ? <TakeOrder /> : null}
          {this.state.orders ? <Orders /> : null}
          <Footer />
        </div>
      </div>
    );
  }
}
export default ShopDash;
