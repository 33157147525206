import React from "react";
import { Row, Col } from "react-bootstrap";
import Card from "../Card/Card";
import jsPDF from "jspdf";
import "jspdf-autotable";

let storeFresh;
//let storeUserId;
// let storeIdValue;
let clientHost;
// let storeDet;
// let storeAddress;
let storeName;
class DunzoReportsComponent extends React.Component {
  constructor(props) {
    super();
    // const userCheck = window.localStorage.getItem("access");
    // storeDet = JSON.parse(userCheck);
    // if (storeDet !== null) {
    //   storeAddress = storeDet.address;
    // } else {
    //   storeAddress = "";
    // }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      // storeName = storeFresh.storeName;
      storeName = JSON.parse(window.localStorage.getItem("clientAccess"))
        .storeName;
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
  }
  pdfCheck = () => {
    if (this.props.dunzoReports) {
      // alert("hgfdfghj");
      const doc = new jsPDF();
      doc.autoTable({ html: "#my-table1" });
      doc.save(`${storeName}_itemwiseReport_${
        this.props.salesDate === "" ? this.props.date : this.props.salesDate
      }to${
        this.props.salesDate2 === "" ? this.props.date : this.props.salesDate2
      }
.pdf`);
    } else {
      alert("No Data");
    }
  };
  render() {
    return (
      <section className="client no-padding-top">
        <div className="container-fluid">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-close">
                <div className="dropdown">
                  <div
                    id="closeCard4"
                    aria-haspopup="true"
                    className="dropdown-toggle"
                  >
                    {/* <i className="fa fa-ellipsis-v"></i> */}
                    <Col md={3}>
                      <Card
                        ctAllIcons
                        content={
                          <Row style={{ position: "absolute", right: "0px" }}>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="provide Start Date"
                              defaultValue={this.props.date}
                              name="getDunzoReportsByDate"
                              max={this.props.date}
                              onChange={this.props.getDunzoReportsByDate}
                              style={{
                                position: "relative",
                                right: "105%",
                                bottom: "5px",
                              }}
                            />
                            <input
                              type="date"
                              className="form-control"
                              placeholder="provide Start Date"
                              defaultValue={this.props.date}
                              name="getDunzoReportsByDate2"
                              max={this.props.date}
                              style={{ position: "relative", bottom: "42px" }}
                              onChange={this.props.getDunzoReportsByDate2}
                            />
                          </Row>
                        }
                      />
                    </Col>
                  </div>
                </div>
              </div>
              <div className="card-header d-flex align-items-center">
                <h3 className="h4">Dunzo Delivery Data</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {/* <div style={{ display: "none" }}> */}
                  <div style={{ height: "400px", overflow: "auto" }}>
                    <table
                      id="my-table1"
                      className="table table-striped table-sm"
                    >
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>Date</th>
                          <th style={{ textAlign: "center" }}>Name</th>
                          <th style={{ textAlign: "center" }}>
                            Pickup Address
                          </th>
                          <th style={{ textAlign: "center" }}>Drop Address</th>
                          <th style={{ textAlign: "center" }}>Order Status</th>
                          <th style={{ textAlign: "center" }}>Distance</th>
                          <th style={{ textAlign: "center" }}>
                            Delivery Charge
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.dunzoReports ? (
                          this.props.dunzoReports.map((p, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  {/* <td>{p.Date.split(" ")[0]}</td> */}
                                  <td style={{ textAlign: "center" }}>
                                    {p.dated}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {p.name}&nbsp;
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      width: "25%",
                                    }}
                                  >
                                    {p.address}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {p.shipping_address}&nbsp;
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {p.order_status === "4"
                                      ? "completed"
                                      : p.order_status === "5"
                                      ? "cancelled"
                                      : p.order_status === "2"
                                      ? "picked_up"
                                      : p.order_status === "1"
                                      ? "accepted"
                                      : p.order_status === "3"
                                      ? "delivered"
                                      : p.order_status === "6"
                                      ? "assigned"
                                      : p.order_status === "7"
                                      ? "Rejected"
                                      : p.order_status === "4"
                                      ? "Order Cancel Request"
                                      : p.order_status === "7"
                                      ? "Waiting for confirmation"
                                      : null}
                                    &nbsp;
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {p.distance === "" ? "0" : null}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {p.delivery_charge}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <div
                            style={{
                              textAlign: "center",
                              padding: "50px",
                              position: "relative",
                              right: "-25%",
                              paddingTop: "25%",
                            }}
                          >
                            <h1 style={{ fontSize: "35px" }}> No Data </h1>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                {this.props.dunzoReports ? (
                  <>
                    {clientHost ? (
                      <a
                        style={{
                          position: "absolute",
                          right: "55%",
                          top: "3%",
                          color: "black",
                        }}
                        href={
                          clientHost.split("/backend/api")[0] +
                          // `/reports/Report/dailySale`
                          `/reports/Report/getDunzoReport?store_id=${
                            storeFresh.storeId
                          }&startDate=${
                            this.props.salesDate
                              ? this.props.salesDate
                              : this.props.date
                          }&endDate=${
                            this.props.salesDate2
                              ? this.props.salesDate2
                              : this.props.date
                          }`
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="fa fa-file-excel-o"></i>
                      </a>
                    ) : null}
                  </>
                ) : null}
              </div>
              <a
                href="#/"
                style={{
                  position: "absolute",
                  right: "60%",
                  padding: "15px",
                  color: "red",
                }}
                onClick={this.pdfCheck}
              >
                <i className="fa fa-file-pdf-o"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default DunzoReportsComponent;
